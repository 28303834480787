<footer class="text-sm bg-white dark:bg-gray-900 border-t border-gray-300 dark:border-gray-900 mt-12">
  <nav class="flex justify-center items-center">
    <app-theme-toggle class="sm:hidden"></app-theme-toggle>
    <a class="px-6 py-4" href="https://github.com/Lyelt" title="Github"><img class="p-0.5 bg-white rounded" src="assets/github-logo.png" /></a>
    <a class="px-6 py-4" href="https://www.linkedin.com/in/nicholasghobrial/" title="LinkedIn"><img src="assets/linkedin-logo.png" /></a>
  </nav>
  <nav class="flex justify-center">
    <p class="text-xs px-6 py-4">	&copy; Nicholas Ghobrial 2021</p>
  </nav>
</footer>
