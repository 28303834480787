<div *ngIf="!results">
    <div *ngFor="let collection of collections" (click)="evaluate(collection)">{{collection.name}}</div>
</div>
<div *ngIf="results" class="mt-6">
    <div *ngFor="let r of results">
        <div class="flex flex-row items-baseline"><div class="font-bold mb-2 ml-1">{{r.originalMonster.name}}</div><div class="text-sm ml-1"> - {{r.totalUniqueTargets}} unique targets</div></div>
        <div *ngFor="let t of r.targetResults" class="ml-4 mb-4">
            <div class="flex flex-row items-baseline"><div class="mb-2 ml-1 underline">{{t.target.target.name}}</div><div class="ml-1 text-sm">({{t.target.propertyName === 'race' ? 'type' : t.target.propertyName}})</div></div>
            <div *ngFor="let s of t.searches" class="ml-8 flex flex-row items-baseline">
                <div>{{s.target.name}}</div><div class="text-sm ml-1">({{s.propertyName === 'race' ? 'type' : s.propertyName}})</div>
            </div>
        </div>
    </div>
</div>