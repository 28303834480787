<div class="pt-8">
    <div class="flex justify-between mb-8">
        <span class="link" (click)="closed.emit()">&larr; Back</span>
        <h3 class="font-bold text-lg md:text-2xl">{{star.name}}</h3>
        <span class="opacity-0"></span>
    </div>
    <div>
        <!-- tab labels -->
        <nav class="flex items-center justify-center space-x-8 text-xs sm:text-sm md:text-base md:space-x-12 lg:space-x-20 mb-20">
            <span class="nav-link nav-link-hover text-center" [ngClass]="{'font-bold border-b-2 border-indigo-400': selectedTab === 'time'}" (click)="selectedTab = 'time'">Time Comparison</span>
            <span class="nav-link nav-link-hover text-center" [ngClass]="{'font-bold border-b-2 border-indigo-400': selectedTab === 'edit'}" (click)="selectedTab = 'edit'">Edit</span>
            <span class="nav-link nav-link-hover text-center" [ngClass]="{'font-bold border-b-2 border-indigo-400': selectedTab === 'archive'}" (click)="selectedTab = 'archive'">Archive</span>
            <span class="nav-link nav-link-hover text-center" [ngClass]="{'font-bold border-b-2 border-indigo-400': selectedTab === 'rta'}" (click)="selectedTab = 'rta'">RTA Guide</span>
        </nav>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-6" *ngIf="selectedTab === 'time'">
            <div *ngFor="let runner of runners" class="md:first:text-right">
                <div *ngIf="getStarTime(runner); let time" class="flex flex-col">
                    <span class="uppercase font-medium tracking-wide pb-4">{{runner.userName}}</span>
                    <span class="pb-4" [ngClass]="{'font-bold tracking-tight text-indigo-600 dark:text-pink-400' : starTimeIsFastest(time)}">{{time.timeDisplay}}</span>
                    <div class="h-96 lg:h-112 xl:h-128">
                        <iframe *ngIf="time.videoUrl" class="w-full h-full" [src]="time.videoUrl | safeYt" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-6" *ngIf="selectedTab === 'edit'">
            <div *ngFor="let runner of runners; let i = index" class="flex flex-col md:first:text-right">
                <span class="uppercase font-medium tracking-wide pb-4">{{runner.userName}}</span>
                <app-edit-star class="hidden md:block" [starTime]="getStarTime(runner)" [runnerName]="runner.userName" [alignment]="i === 0 ? 'right' : 'left'"></app-edit-star>
                <app-edit-star class="md:hidden" [starTime]="getStarTime(runner)" [runnerName]="runner.userName" alignment="left"></app-edit-star>
            </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-6" *ngIf="selectedTab === 'archive'">
            <div *ngFor="let runner of runners" class="flex flex-col">
                <span class="uppercase tracking-wide mb-6 text-center">{{runner.userName}}</span>
                <app-archive [star]="star" [runner]="runner"></app-archive>
            </div>
        </div>

        <div *ngIf="selectedTab === 'rta'">
            <div class="w-full h-256" *ngIf="star.rtaGuideUrl">
                <iframe class="w-full h-full" [src]="star.rtaGuideUrl | safe"></iframe>
            </div>
        </div>
    </div>
</div>