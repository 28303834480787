<div class="flex items-center mb-2">
  <mat-icon *ngIf="!readOnly" class="cursor-pointer " (click)="delete()">delete_forever</mat-icon>
  <span class="text-lg font-semibold ml-4">Game {{game.gameNumber}}</span>
</div>
<div class="mb-2">
  <mat-chip-list *ngIf="game.id == 0" class="chip-list">
    <mat-chip *ngFor="let score of getScoresToDisplay()" (click)="addScore(score.value)" class="score-button" color="primary" selected>{{score.display}}</mat-chip>
    <mat-chip (click)="isSplit = !isSplit" class="score-button" color="accent" selected>{{isSplit ? "Split Off" : "Split On"}}</mat-chip>
    <mat-chip (click)="back()" color="accent" selected>Back</mat-chip>
    <mat-chip (click)="save()" color="accent" selected>Save</mat-chip>
  </mat-chip-list>
</div>
<div class="flex justify-around bg-gray-200 dark:bg-gray-900 py-2 rounded-lg sm:rounded-2xl text-center text-2xs sm:text-xs md:text-sm lg:text-base" tabindex="0" (keyup)="handleKey($event)">
  <div class="w-1/12 flex flex-col sm:pr-2" *ngFor="let frame of game.frames" (click)="selectFrame(frame.frameNumber)" [class.selected]="game.id == 0 && frame.frameNumber == currentFrame">
    <span class="font-semibold">{{frame.frameNumber}}</span>
    <div class="flex">
      <div class="w-1/2 h-full pt-1 pb-0.5 float-left"
           [ngClass]="{'w-1/3': frame.frameNumber == 10, 'font-bold underline text-red-600 dark:text-yellow-600': frame.isSplit}">
        {{getRoll1Display(frame)}}
      </div>
      <div class="w-1/2 h-full pt-1 pb-0.5 float-left border-l border-b border-black dark:border-pink-900"
           [ngClass]="{'w-1/3': frame.frameNumber == 10}"
           *ngIf="frame.roll2Score != null">
        {{getRoll2Display(frame)}}
      </div>
      <div class="w-1/2 h-full pt-1 pb-0.5 float-right border-l border-b border-black dark:border-pink-900"
           [ngClass]="{'w-1/3': frame.frameNumber == 10}"
           *ngIf="frame.frameNumber == 10 && frame.roll3Score != null">
        {{getRoll3Display(frame)}}
      </div>
    </div>
    <div class="pt-1 font-medium">{{getScoreSoFar(game, frame)}}</div>
  </div>
</div>
