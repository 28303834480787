<blog-article>
  <blog-header>
    <blog-title>Typing Analyzer</blog-title>
    <blog-date>Future Date</blog-date>
    <blog-subtitle>Gather interesting and useful stats about your typing patterns.</blog-subtitle>
  </blog-header>

  <blog-section>
    <blog-section-title>In Progress</blog-section-title>
    <p>
      This blog post is in progress. Come back later!
    </p>
  </blog-section>
</blog-article>
