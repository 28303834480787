<div>
  <div *ngIf="!selectedStar" class="flex flex-col justify-center container mx-auto px-8" [class.max-w-6xl]="!compact">
    <h1 class="font-bold text-2xl mb-4 text-center">Super Mario 64</h1>
    <div class="flex justify-between mb-6">
      <div class="flex space-x-8">
        <span class="link" (click)="toggleCollapsed()">{{expanded?.length > 0 ? "Collapse" : "Expand"}} All</span>
        <span class="link hidden md:inline" (click)="changeView()">{{compact ? "List" : "Compact"}} View</span>
      </div>
      <mat-icon class="cursor-pointer" [matMenuTriggerFor]="menu">menu</mat-icon>
    </div>
    <mat-menu #menu="matMenu">
      <span mat-menu-item class="menu-item" routerLink="bingo">Bingo</span>
      <a mat-menu-item class="menu-item" target="_blank" href="https://drive.google.com/open?authuser=0&id=1_cOIEnuKIQ-3LA_U0ygpiL87PTSBPlHmKDId0vC7alo">Single Star WRs</a>
      <a mat-menu-item class="menu-item" target="_blank" href="https://drive.google.com/open?authuser=0&id=1J20aivGnvLlAuyRIMMclIFUmrkHXUzgcDmYa31gdtCI">Ultimate Star Spreadsheet</a>
      <a mat-menu-item class="menu-item" target="_blank" href="https://docs.google.com/spreadsheets/d/1i1BYP4hv6Uy6clz3YclEVx_Z_RK1Tx1uLYjdwi4iWmE/edit#gid=0">Competition</a>
    </mat-menu>
    <div class="mb-12">
      <app-course *ngIf="categories" 
        [course]="categories" 
        [isCollapsed]="courseIsCollapsed(categories)"
        (collapseToggled)="toggleCourseCollapsed(categories)"
        (starSelected)="showStarDetails($event)"></app-course>
    </div>
    <div [ngClass]="{'flex flex-col space-y-8': !compact, 'md:grid grid-cols-2 gap-x-4 gap-y-8': compact}">
      <app-course *ngFor="let course of getVisibleCourses()" 
        [course]="course" 
        [isCollapsed]="courseIsCollapsed(course)"
        (collapseToggled)="toggleCourseCollapsed(course)"
        (starSelected)="showStarDetails($event)"></app-course>
    </div>
  </div>
  <div *ngIf="selectedStar" class="px-8">
    <app-star-details [star]="selectedStar" (closed)="closeStar()"></app-star-details>
  </div>
</div>
