import { Component } from '@angular/core';

@Component({
  selector: 'blog-code',
  templateUrl: './blog-code.component.html',
  styleUrls: ['./blog-code.component.scss']
})
export class BlogCodeComponent {

}
