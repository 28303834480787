import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recap2020',
  templateUrl: './recap2020.component.html',
  styleUrls: ['./recap2020.component.scss']
})
export class Recap2020Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
