<form [formGroup]="form" class="flex flex-col" [class.items-end]="alignment === 'right'" [class.items-start]="alignment === 'left'">
  <div class="md:w-1/3 flex flex-col mb-6">
    <label for="time" class="pb-1" [class.text-right]="alignment === 'right'">Time</label>
    <input type="text" [class.text-right]="alignment === 'right'" placeholder="Time (hh:mm:ss.ff)" formControlName="time" />
  </div>

  <div class="md:w-1/3 flex flex-col mb-8">
    <label for="videoUrl" class="pb-1" [class.text-right]="alignment === 'right'">Youtube Watch Code</label>
    <input type="text" [class.text-right]="alignment === 'right'" placeholder="Youtube Watch Code" formControlName="videoUrl" />
  </div>

  <div>
    <button type="button" class="btn btn-primary" (click)="save()">Save Changes</button>
  </div>
</form>
