<div class="flex flex-col justify-center">
  <div class="grid grid-cols-2 sm:grid-cols-3 gap-2 p-2">
    <div *ngFor="let stat of stats" 
      class="p-2 rounded-md bg-gray-300 dark:bg-gray-700 flex flex-col items-center text-center"
      [matTooltip]="stat.details" matTooltipPosition="above">
      <span class="font-bold pb-2 text-xl">{{stat.value}}{{stat.unit}}</span>
      <span class="text-xs">{{stat.name}}</span>
    </div>
  </div>
  <!-- <mat-progress-spinner class="self-center my-6" color="accent" mode="indeterminate" *ngIf="statsLoading"></mat-progress-spinner> -->
</div>
