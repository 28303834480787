<div class="flex flex-col sm:flex-row sm:justify-between sm:items-center px-4 pb-4 w-full">
  <div class="mr-4">
    <div *ngIf="card.card_Sets && card.card_Sets.length > 0" (click)="$event.stopPropagation()">
      <select class="form-select" [(ngModel)]="selectedSet">
        <option *ngFor="let set of card.card_Sets" [ngValue]="set">{{set.set_Code}} {{set.set_Rarity_Code}}</option>
      </select>
    </div>
    <p *ngIf="!card.card_Sets || card.card_Sets.length == 0" class="link text-gray-400">No Sets Found</p>
  </div>
  <div class="flex mt-2 sm:mt-0">
    <span class="cursor-pointer text-red-500 hover:text-red-600" (click)="removeCard($event)"><mat-icon>remove_circle</mat-icon></span>
    <span class="px-2">{{getQuantityDisplay()}}</span>
    <span class="cursor-pointer text-green-500 hover:text-green-600" (click)="addCard($event)"><mat-icon>add_circle</mat-icon></span>
  </div>
</div>
