import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tier-list',
  templateUrl: './tier-list.component.html',
  styleUrls: ['./tier-list.component.scss']
})
export class TierListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
