<header class="fixed top-0 z-999 w-full bg-white dark:bg-gray-900 shadow-lg py-4 px-2 text-xs sm:text-base overflow-x-auto">
  <nav class="flex items-center">
    <a href="#" class="nav-link hidden sm:inline"><mat-icon routerLink="">home</mat-icon></a>
    <span class="flex-shrink flex-auto"></span>
    <a class="nav-link nav-link-hover" routerLink="" href="#projects" (click)="scrollToElement('projects')">Projects</a>
    <a class="nav-link nav-link-hover" routerLink="" href="#skills" (click)="scrollToElement('skills')">Skills</a>
    <a class="nav-link nav-link-hover" routerLink="" href="#about" (click)="scrollToElement('about')">About</a>
    <a class="nav-link nav-link-hover" routerLink="" href="#contact" (click)="scrollToElement('contact')">Contact</a>
    <a class="nav-link nav-link-hover" routerLink="blog">Blog</a>
    <span class="flex-shrink flex-auto sm:hidden"></span>
    <span class="flex cursor-pointer" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon class="sm:ml-2">more_vert</mat-icon>
    </span>
    <mat-menu #menu="matMenu">
      <span mat-menu-item routerLink="bowling" class="menu-item"><mat-icon svgIcon="bowling"></mat-icon>Bowling</span>
      <span mat-menu-item routerLink="yugioh" class="menu-item"><mat-icon svgIcon="yugioh"></mat-icon>Yu-Gi-Oh</span>
      <!-- <span mat-menu-item routerLink="tier" class="menu-item"><mat-icon>format_list_numbered</mat-icon>Tier Lists</span> -->
      <span mat-menu-item routerLink="initiative" class="menu-item"><mat-icon>repeat_one</mat-icon>Initiative</span>
      <!-- <span mat-menu-item routerLink="recipes" class="menu-item"><mat-icon>menu_book</mat-icon>Recipes</span> -->
      <span mat-menu-item routerLink="speedrun" class="menu-item"><mat-icon>sports_esports</mat-icon>Speedrun</span>
      <span mat-menu-item routerLink="gamenight" class="menu-item"><mat-icon svgIcon="gameNight"></mat-icon>Game Night</span>
      <span mat-menu-item routerLink="login" class="last-menu-item flex items-center" *ngIf="loggedInUserName(); let username">
        <mat-icon class="transform rotate-180">exit_to_app</mat-icon>Logout {{username}}
      </span>
    </mat-menu>
    <span class="flex-shrink flex-auto"></span>
    <app-theme-toggle class="hidden sm:inline"></app-theme-toggle>
  </nav>
</header>
