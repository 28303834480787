<div class="flex flex-col justify-center -mt-20 -mb-48 select-none" *ngIf="myDog !== nobody">
  <div class="px-4">
    <h1 class="text-xl lg:text-4xl text-center hidden sm:block">Who is Outside?</h1>
    <div class="flex flex-row justify-between items-center text-2xl sm:text-4xl lg:text-6xl">
      <h2 class="sm:font-semibold">{{time | date: 'mediumTime'}}</h2>
      <div class="flex">
        <div><app-theme-toggle></app-theme-toggle></div>
        <div class="px-4 cursor-pointer dark:text-white"><mat-icon (click)="silence()">notifications_off</mat-icon></div>
      </div>
    </div>
    <hr class="border-gray-300 opacity-30 mb-12 mt-4" />
  </div>

  <div class="container mx-auto flex flex-col space-y-8 lg:space-y-32 px-4" *ngIf="connected">
    <div class="flex flex-row justify-between items-center w-full space-x-4">

      <div class="w-1/3">
        <app-dog-indicator
          (click)="toggleDog(myDog)" 
          [dogName]="getDogName(myDog)" 
          [isOutside]="outsideDog === myDog"
          borderClass="border-indigo-600 dark:border-indigo-200" 
          textClass="text-indigo-900 dark:text-indigo-200" 
          bgClass="bg-indigo-200 dark:bg-indigo-900"
          animatedBorderClass="border-indigo-900 dark:border-indigo-200"></app-dog-indicator>
      </div>

      <div class="w-1/3">
        <app-dog-indicator
          (click)="toggleDog(nobody)" 
          [dogName]="getDogName(nobody)" 
          [isOutside]="outsideDog === nobody"
          borderClass="border-green-600 dark:border-green-200" 
          textClass="text-green-900 dark:text-green-200" 
          bgClass="bg-green-200 dark:bg-green-900"
          animatedBorderClass="border-green-900 dark:border-green-200"></app-dog-indicator>
      </div>
      
      <div class="w-1/3">
          <app-dog-indicator
            (click)="toggleDog(otherDog)" 
            [dogName]="getDogName(otherDog)" 
            [isOutside]="outsideDog === otherDog"
            borderClass="border-red-500 dark:border-red-200" 
            textClass="text-red-900 dark:text-red-200" 
            bgClass="bg-red-200 dark:bg-red-800"
            animatedBorderClass="border-red-900 dark:border-red-200"></app-dog-indicator>
      </div>
    </div>
    <div class="flex sm:flex-row sm:justify-between" [ngClass]="{'flex-col space-y-4 sm:flex-row sm:space-y-0' : showingWarningBox()}">
      <div class="w-1/3">
        <div class="text-xs lg:text-lg italic underline flex flex-col" (click)="showingOldTimes = !showingOldTimes">
          <span>since {{lastUpdatedTime | date: 'shortTime'}}</span>
          <span>({{getDurationDisplay()}})</span>
        </div>
        <div *ngIf="showingOldTimes"><div *ngFor="let dt of dogTimes" class="text-xs italic"><span>{{dt.timestamp + 'Z' | date: 'mediumTime'}}</span> - <span>{{getDogName(dt.dog)}}</span></div></div>
      </div>
      <div [ngClass]="{'w-2/3 flex justify-end': showingAlertMeBox()}">
        <button class="btn border border-red-900 text-red-900 dark:border-red-200 dark:text-red-200 flex flex-row items-center space-x-2 lg:text-2xl " (click)="awaitingAlert = true" *ngIf="showingAlertMeBox()">
          <mat-icon>add_alert</mat-icon>
          <div>Alert me when {{getDogName(otherDog)}}'s inside</div>
        </button>
        <div class="flex flex-col space-y-2 sm:flex-row sm:justify-between bg-orange-100 border-l-4 border-orange-500 text-orange-700 dark:bg-orange-700 dark:text-orange-100 dark:border-orange-200 p-4 lg:text-2xl" *ngIf="showingWarningBox()">
          <div class="flex flex-row space-x-2 pr-4 items-center">
              <mat-icon class="animate-pulse">{{awaitingAlert ? "info" : "warning"}}</mat-icon>
              <div *ngIf="awaitingAlert && !nudgeAcknowledged">You will be alerted when {{getDogName(otherDog)}} comes inside.</div>
              <div *ngIf="nudgeAcknowledged && !currentlyBeingNudged">{{getDogName(otherDog)}} will be inside soon...</div>
              <div *ngIf="currentlyBeingNudged && !nudgeAcknowledged">Please bring {{getDogName(myDog)}} inside!</div>
              <div *ngIf="currentlyBeingNudged && nudgeAcknowledged">Thanks! Let us know when it's all clear.</div>
          </div>
          <div class="flex flex-row space-x-4">
            <button *ngIf="awaitingAlert" class="btn bg-orange-700 text-orange-100 dark:bg-orange-100 dark:text-orange-700 flex flex-row space-x-2 items-center" (click)="nudge()"><mat-icon>notifications</mat-icon><span>Nudge</span></button>
            <button *ngIf="awaitingAlert" class="btn border border-orange-700 dark:border-orange-100" (click)="cancelAlert()">Cancel</button>
            <button *ngIf="currentlyBeingNudged && !this.nudgeAcknowledged" class="btn bg-orange-700 text-orange-200 dark:bg-orange-200 dark:text-orange-700" (click)="acknowledgeNudge()">Working on it...</button>
            <button *ngIf="currentlyBeingNudged" class="btn border border-orange-700 dark:border-orange-100" (click)="toggleDog(nobody)">All Clear</button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  
  <div *ngIf="!connected" (click)="refresh()" class="bg-red-200 dark:bg-red-800 rounded text-red-900 dark:text-red-100 shadow p-6 mx-auto text-4xl">
    <span>A connection error occurred. </span>
    <span class="font-bold underline">Click here to reconnect.</span>
  </div>
  
</div>
<div class="flex flex-col justify-center px-8" *ngIf="myDog === nobody">
  <h1 class="font-bold text-2xl mb-12 text-center -mt-12">My dog is...</h1>
  <div class="flex flex-row justify-between">
      <button class="btn border border-indigo-400" (click)="claimPenny()">Penny</button>
      <button class="btn border border-indigo-400" (click)="claimCalvin()">Calvin</button>
  </div>
</div>

