<div class="chart-container">
  <ngx-charts-line-chart #chart
                         *ngIf="!dataLoading && category.chartType == 'line'"
                         [scheme]="colorScheme"
                         schemeType="ordinal"
                         [results]="bowlingData"
                         [legend]="true"
                         [legendTitle]=null
                         [legendPosition]=null
                         gradient="false"
                         xAxis="true"
                         yAxis="true"
                         showXAxisLabel="true"
                         [yAxisLabel]="yAxisLabel"
                         autoScale="true"
                         timeline="true"
                         showGridLines="true"
                         [curve]="curve"
                         (select)="select($event)"
                         class="chart-line-selector">
  </ngx-charts-line-chart>
  <ngx-charts-bar-horizontal #barChart
                             *ngIf="!dataLoading && category.chartType == 'horizontalBar'"
                             [scheme]="colorScheme"
                             schemeType="ordinal"
                             [results]="barChartData"
                             xAxis="true"
                             yAxis="true"
                             [legend]="true"
                             showXAxisLabel="true"
                             showYAxisLabel="true"
                             yAxisLabel="Score"
                             xAxisLabel="Number of games"
                             class="chart-horizontal">
  </ngx-charts-bar-horizontal>
</div>
<div class="flex flex-col justify-center" *ngIf="dataLoading">
  <mat-progress-spinner class="self-center my-6" color="accent" mode="indeterminate"></mat-progress-spinner>
</div>

<!-- 
  showYAxisLabel="true"
  
                         [legendTitle]=null
                         [legendPosition]=null
                         (legendLabelClick)="onLegendLabelClick($event)" -->