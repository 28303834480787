<mat-dialog-content class="flex flex-col">
  <div *ngIf="selectedTabIndex === 0">
    <div class="flex flex-col py-6 lg:px-8 items-center">
      <div class="flex flex-col items-center w-full p-4 sm:flex-row sm:justify-between">
        <div class="flex flex-col flex-grow mb-8 sm:m-4">
          <label class="flex flex-col">
            <span class="pb-2 font-medium">Choose a session</span>
            <select [(ngModel)]="selectedSession">
              <option></option>
              <option *ngFor="let session of sessions" [ngValue]="session">{{session.id}}. {{session.date | date:'fullDate':'UTC'}}</option>
            </select>
          </label>
          <button type="button" class="btn btn-primary mt-4" (click)="startAddingGames(false)" [disabled]="!selectedSession">View Existing Session</button>
        </div>

        <div class="flex flex-col flex-grow m-4">
          <label class="flex flex-col">
            <span class="pb-2 font-medium">Add a new session</span>
            <input type="date" [(ngModel)]="selectedDate" placeholder="Add a new session">
          </label>
          <button type="button" class="btn btn-secondary mt-4" (click)="startAddingGames(true)" [disabled]="!selectedDate">Start New Session</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedTabIndex === 1">
    <div class="flex flex-col">
      <app-bowling-add-game *ngIf="selectedSession" [session]="selectedSession" [userName]="selectedSessionUser"></app-bowling-add-game>
      <div class="flex justify-between mt-10">
        <button type="button" class="btn btn-secondary" (click)="changeTab(0)">Back</button>
        <!-- <button type="button" class="btn btn-primary" (click)="close()">Done</button> -->
      </div>
    </div>
  </div>
</mat-dialog-content>
