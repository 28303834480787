<div class="px-12 pt-8">
    <h2 class="text-2xl mb-16 text-center">Schedule a <span class="emphasis">Game Night</span></h2>
    <div class="flex flex-col items-center">
        <div class="flex flex-col w-full sm:w-1/2">
            <input type="date" [(ngModel)]="gameNightDate" [ngModel]="gameNightDate | date: 'yyyy-MM-dd'" name="date" placeholder="Choose date" />
            <label for="date" class="pt-2 font-light text-sm">{{gameNightDate | date: 'EEEE, MMMM d'}}</label>
        </div>
        <div class="flex flex-col mt-12">
            <div class="flex flex-wrap space-x-4">
                <button *ngFor="let user of users"
                    class="px-2 py-1 text-lg rounded-lg border-2 cursor-pointer mb-2"
                    [ngClass]="{'bg-pink-600 text-pink-50 font-bold': user.id === selectedUserId}"
                    (click)="selectedUserId = user.id">
                    {{user.userName}}
                </button>
            </div>
        </div>
    </div>

    <div class="mt-24 flex justify-end">
        <button type="button" class="btn btn-primary" (click)="createGameNight()">Schedule It &rarr;</button>
    </div>
</div>