
<div class="select-none relative">
    <div class="font-medium text-center text-gray-500 border-b border-gray-200 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700 bg-white shadow-lg fixed top-0 z-999 w-full">
        <ul class="flex flex-flex-row justify-around items-center -mb-px uppercase">
            <li>
                <a routerLink="/gamenight/home" 
                class="inline-block px-4 pb-2 pt-6"
                [ngClass]="{'text-indigo-600 border-b-2 border-indigo-600 dark:text-indigo-300 dark:border-indigo-300': isActive('home') || isActive(undefined)}">Home</a>
            </li>
            <li>
                <a routerLink="/gamenight/games" 
                class="inline-block px-4 pb-2 pt-6"
                [ngClass]="{'text-indigo-600 border-b-2 border-indigo-600 dark:text-indigo-300 dark:border-indigo-300': isActive('games')}">Games</a>
            </li>
            <li>
                <a routerLink="/gamenight/meals" 
                class="inline-block px-4 pb-2 pt-6"
                [ngClass]="{'text-indigo-600 border-b-2 border-indigo-600 dark:text-indigo-300 dark:border-indigo-300': isActive('meals')}">Meals</a>
            </li>
            <!-- <li>
                <mat-icon>chat</mat-icon>
            </li> -->
        </ul>
    </div>
    <div class="container mx-auto">
        <app-game-night-home *ngIf="isActive('home') || isActive(undefined)"></app-game-night-home>
        <app-games *ngIf="isActive('games')"></app-games>
        <app-meals *ngIf="isActive('meals')">meals</app-meals>
    </div>
    <div class="fixed right-0 bottom-0 left-0 bg-white dark:bg-gray-900">
        <app-game-night-schedule></app-game-night-schedule>
    </div>
    <div class="absolute top-1 right-1"><app-theme-toggle></app-theme-toggle></div>
</div>