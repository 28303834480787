<div *ngIf="getSelectedGameNight() && !isAddingGameNight()">
    <div class="flex flex-col space-y-4 text-xl md:text-2xl items-center w-full mb-12">
        <div class="flex flex-col items-center md:flex-row md:items-baseline md:space-x-2">
            <span>{{(isSelectedGameNightNext() ? "Next" : "This")}} game night is</span>
            <span class="emphasis">{{getSelectedGameNight().date | date: 'EEEE, MMMM d'}}</span>
        </div> 
        <div>It's <span class="emphasis">{{getSelectedGameNight().user?.userName}}</span>'s night</div>
        <div>
            <div *ngIf="getSelectedGameNight()?.meal">We're having 
                <span class="emphasis">{{gnService.selectedGameNight.meal.name | lowercase}}</span>
            </div>
            <a class="text-base action" routerLink="/gamenight/meals" *ngIf="!getSelectedGameNight().meal && userCanSetMeal()">{{userCanSetMeal() ? 'Select' : 'Suggest'}} a meal &rarr;</a>
        </div>
    </div>
    
    <div class="w-full" >
        <div class="flex flex-col items-center space-y-12">
            <div class="w-full mx-12 max-w-xs md:max-w-md py-4 px-8 border rounded-lg shadow-lg bg-white dark:bg-gray-900 relative" >
                <div class="flex flex-col space-y-2" *ngIf="getSelectedGameNight().games?.length === 0">
                    <div class="flex flex-col items-center">
                        <img class="h-36" src="assets/icons/camping.svg"/>
                        <span class="text-xs">No Games Selected</span>
                    </div>
                </div>
                <div class="flex flex-col space-y-4">
                    <div *ngFor="let g of getSelectedGameNight().games" class="before:content-['•'] before:text-pink-600 font-semibold">
                        {{g.name}}
                    </div>
                </div>
                <div class="absolute top-4 right-4 text-gray-600"
                routerLink="/gamenight/games"
                *ngIf="gameNightBelongsToLoggedInUser()"><mat-icon>edit</mat-icon></div>
            </div>
            <div class="w-full mx-12 max-w-xs md:max-w-md border rounded-lg shadow-lg bg-white dark:bg-gray-900">
                <app-game-night-user-statuses></app-game-night-user-statuses>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isAddingGameNight()"><app-create-game-night></app-create-game-night></div>
<app-user-status-confirmation></app-user-status-confirmation>