<div class="container mx-auto p-6 max-w-xl">
  <div class="flex flex-col rounded-lg shadow-lg bg-white dark:bg-gray-900 p-6">
    <div class="flex flex-col items-center mb-6">
      <h2 class="font-bold text-2xl mb-2">Login</h2>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="px-4 pb-2">
      <div class="flex flex-col justify-center">
         <div class="flex flex-col mb-4">
            <label for="username" class="pb-1 font-medium">Username</label>
            <input type="text" formControlName="username" [ngClass]="{ 'border-0 ring-2 ring-red-600 dark:ring-pink-700': submitted && f.username.errors }" />
          </div>
          <div class="flex flex-col">
            <label for="password" class="pb-1 font-medium">Password</label>
            <input type="password" formControlName="password" [ngClass]="{ 'border-0 ring-2 ring-red-600 dark:ring-pink-700': submitted && f.password.errors }" />
          </div>
      </div>
      <div class="mt-5 flex justify-between items-center flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <button type="submit" class="btn btn-primary flex-grow w-full" [disabled]="loading">Login</button>
        <button type="button" (click)="guestLogin()" class="btn flex-grow w-full bg-gray-300 text-gray-800 hover:bg-gray-400 dark:text-gray-100 dark:bg-gray-600 dark:hover:bg-gray-700" [disabled]="loading">
          Continue as guest &rarr;
        </button>
      </div>
    </form>
  </div>
</div>


