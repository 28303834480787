import { Component } from '@angular/core';

@Component({
  selector: 'app-september2023',
  templateUrl: './september2023.component.html',
  styleUrls: ['./september2023.component.scss']
})
export class September2023Component {

}
