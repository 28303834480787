import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blog-h3',
  templateUrl: './blog-h3.component.html',
  styleUrls: ['./blog-h3.component.scss']
})
export class BlogH3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
