<div class="flex flex-col">
  <h2 class="font-semibold text-center my-6">{{session.date | date: 'fullDate':'UTC'}}</h2>
  <div class="flex items-center justify-around flex-wrap" *ngIf="!readOnly">
    <app-bowling-select-user [initialUserId]="userId"
                             [label]="'View games for user...'"
                             (selectionChange)="selectUser($event)" #userSelect></app-bowling-select-user>
    <button type="button" class="btn btn-primary" (click)="addGame()">Add Game</button>
  </div>
  <app-bowling-game [readOnly]="readOnly" class="mt-8" *ngIf="newGame" [game]="newGame" (gameSaved)="saveGame($event)" (gameDeleted)="deleteGame($event)"></app-bowling-game>
  <app-bowling-game [readOnly]="readOnly" class="mt-8" *ngFor="let game of filteredGames" [game]="game" (gameDeleted)="deleteGame($event)"></app-bowling-game>
  <span class="self-end font-semibold text-xl mt-6 pr-2">Total: {{getSessionTotal()}}</span>
</div>
