<div class="py-4 px-2">
  <div class="flex flex-col">
    <div class="mb-6 flex justify-between w-full">
      <p class="link" (click)="back()">&larr; Back to Collections</p>
      <div class="flex flex-col items-center">
        <h2 class="text-xl">{{collection.name}}</h2>
        <h5 class="text-xs">{{getCardQuantity(null)}} cards, ${{getSectionPrice(null).toFixed(2)}}</h5>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-x-24 lg:grid-cols-2 lg:gap-24 xl:gap-32">
      <div *ngFor="let section of this.collection.sections" class="w-full mb-12 flex flex-col lg:w-auto lg:flex-grow">
        <app-card-search *ngIf="editingSection && editingSection == section"
                         placeholder="Editing contents of {{editingSection}}"
                         [collection]="collection"
                         [section]="editingSection"
                         (cardAdded)="refresh()"
                         (cardRemoved)="refresh()"></app-card-search>
        <div class="flex flex-col items-center">
          <div class="flex text-center mb-2 hover:text-gray-800 hover:border-b cursor-pointer" (click)="startEditing(section)"><h3 class="font-semibold mr-2">{{section}}</h3><mat-icon>edit</mat-icon></div>
          <h5 class="text-sm">({{getCardQuantity(section)}} cards, ${{getSectionPrice(section).toFixed(2)}})</h5>
        </div>
        <div *ngFor="let subSection of getSubSections(section)" class="mt-8 first:mt-0">
          <h4 class="font-semibold">{{getSubSectionDisplay(subSection)}}</h4>
          <div *ngFor="let c of getCardList(section, subSection)" class="flex py-2">
            <span *ngIf="editingSection && editingSection == section" class="cursor-pointer text-red-500 hover:text-red-600 pr-2" (click)="removeCard(c.setCard)"><mat-icon>remove_circle</mat-icon></span>
            <div class="flex justify-between flex-grow">
              <span><span class="font-bold">{{c.setCard.quantity}}x</span><span class="pl-2">{{c.ygoCard.name}}</span><span class="pl-2">{{c.ygoCard.set_Rarity_Code}}</span></span>
              <a class="link" [href]="getCardLink(c.ygoCard)" target="_blank">${{getCardPrice(c).toFixed(2)}}</a>
            </div>
            <span *ngIf="editingSection && editingSection == section" class="cursor-pointer text-green-500 hover:text-green-600 pl-2" (click)="addCard(c.setCard)"><mat-icon>add_circle</mat-icon></span>
          </div>
        </div>
        <div *ngIf="getSubSections(section).length == 0" class="flex bg-yellow-200 p-5 text-center mt-6 rounded-lg font-semibold text-yellow-700 items-center">
          <mat-icon class="mr-2">warning</mat-icon><p> Add a card to "{{section}}" to save it!</p>
        </div>
      </div>
    </div>
    <div>
      <form (submit)="addSectionToCollection()"
            class="flex justify-between"
            name="section">
        <input class="flex-grow mr-4 sm:mr-6" type="text" placeholder="Add a section"
               [(ngModel)]="newSectionName" name="sectionName" required />
        <button type="submit" class="btn btn-secondary">Add</button>
      </form>
    </div>
  </div>
</div>
