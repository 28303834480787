<div class="flex flex-col justify-center mt-8 md:self-center text-center">
    <mat-tab-group dynamicHeight mat-align-tabs="center">
      <!-- <mat-tab label="Overview">
        <ng-template matTabContent>
          <app-bowling-stat statCategory="Overall"
                            [userId]="userId"
                            [startTime]="startTime"
                            [endTime]="endTime"></app-bowling-stat>
        </ng-template>
      </mat-tab> -->
      <mat-tab label="Counts">
        <ng-template matTabContent>
          <app-bowling-stat statCategory="Count"></app-bowling-stat>
        </ng-template>
      </mat-tab>
      <mat-tab label="Splits">
        <ng-template matTabContent>
          <app-bowling-stat statCategory="Split"></app-bowling-stat>
        </ng-template>
      </mat-tab>
      <mat-tab label="Records">
        <ng-template matTabContent>
          <app-bowling-stat statCategory="Record"></app-bowling-stat>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>