import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blog-section-title',
  templateUrl: './blog-section-title.component.html',
  styleUrls: ['./blog-section-title.component.scss']
})
export class BlogSectionTitleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
