<div class="flex flex-col justify-center md:px-8">
  <div class="mb-6">
    <div class="flex items-center justify-between px-4 mb-6">
      <span class="opacity-0"></span>
      <h1 class="font-bold text-2xl">Bowling Scores</h1>
      <span class="opacity-0" *ngIf="!currentUserId"></span>
      <mat-icon class="cursor-pointer" *ngIf="currentUserId" [matMenuTriggerFor]="menu">menu</mat-icon>
    </div>
    <mat-menu #menu="matMenu">
      <span mat-menu-item class="menu-item" (click)="setView('overview')" [class.bg-indigo-100]="view === 'overview'"><mat-icon>score</mat-icon>Overview</span>
      <span mat-menu-item class="menu-item" (click)="setView('stats')" [class.bg-indigo-100]="view === 'stats'"><mat-icon>query_stats</mat-icon>Stats Report</span>
      <span mat-menu-item class="menu-item" (click)="setView('games')" [class.bg-indigo-100]="view === 'games'"><mat-icon>view_list</mat-icon>View Games</span>
      <span mat-menu-item class="menu-item" (click)="setView('edit')" [class.bg-indigo-100]="view === 'edit'" *ngIf="userCanEdit()"><mat-icon>edit</mat-icon>Add Games</span>
      <span mat-menu-item class="menu-item" (click)="currentUserId = null" [class.bg-indigo-100]="!currentUserId"><mat-icon>people_alt</mat-icon>Change Bowler</span>
    </mat-menu>
    <div class="max-w-md px-6 mx-auto" *ngIf="!currentUserId">
      <app-bowling-select-user [initialUserId]="currentUserId" [label]="'Choose bowler...'" (selectionChange)="selectUser($event)"></app-bowling-select-user>
    </div>
  </div>
  <div *ngIf="currentUserId" class="flex flex-col">
    <div class="container mx-auto max-w-6xl px-4">
      <bowling-filters></bowling-filters>
      <div *ngIf="dataFound">
        <div *ngIf="view === 'overview'">
          <div class="mb-8">
            <app-bowling-stat statCategory="Overall"></app-bowling-stat>
          </div>
          <app-bowling-chart [category]="currentSeriesCategory" (dataPointClicked)="showSessionDetails($event)"></app-bowling-chart>
        </div>
        <div *ngIf="view === 'stats'">
          <bowling-stats></bowling-stats>
        </div>
        <div *ngIf="view ==='games'">
          <app-bowling-view-games></app-bowling-view-games>
        </div>
      </div>
      <div *ngIf="view === 'edit'">
        <app-bowling-start-session></app-bowling-start-session>
      </div>
      <div *ngIf="!dataFound" class="flex justify-center">
        <div class="flex items-center justify-center space-x-2 rounded p-4 font-medium bg-indigo-600 text-indigo-50 dark:bg-pink-700 dark:text-pink-100 ">
          <mat-icon>help_outline</mat-icon>
          <p>No data found for this criteria.</p>
        </div>
      </div>
    </div>
  </div>
</div>