<div class="fixed z-topmost inset-0 w-full h-full overflow-auto bg-black bg-opacity-50" *ngIf="alertService.isVisible">
    <div #modal class="bg-red-50 dark:bg-red-900 text-red-900 dark:text-red-200 mx-auto my-8 w-4/5 xl:w-1/2 rounded-lg text-sm md:text-base relative">
        <button type="button" 
                        class="absolute top-0 right-4 cursor-pointer text-2xl text-red-800 hover:text-red-900 dark:text-red-200 dark:hover:text-red-300 focus:outline-none" 
                        (click)="closeModal()">&times;</button>
        <div class="flex flex-col pb-12 pt-6 px-6">
            <div class="flex justify-start items-center font-bold mb-4 text-lg space-x-4">
                <mat-icon>error_outline</mat-icon>
                <span>{{alertService.alertTitle}}</span>
            </div>
            <span>{{alertService.alertMessage}}</span>
        </div>
        <div class="flex justify-between rounded-b-lg bg-red-100 dark:bg-red-300 px-4 py-3">
            <a class="btn text-red-900 hover:bg-red-200 focus:ring-2 focus:ring-red-900 font-bold" target="_blank" [href]="getGithubIssueUrl()">Report Issue</a>
            <button type="button" class="btn border border-red-900 text-red-900 hover:bg-red-200 dark:border-red-900 focus:outline-none focus:ring-2 focus:ring-red-900 font-bold" (click)="closeModal()">OK</button>
        </div>
    </div>
</div>