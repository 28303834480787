<div class="flex flex-row items-center px-4 py-4">
    <ol class="flex items-center w-full overflow-x-auto">
        <li *ngFor="let gn of gameNights"
            (click)="selectGameNight(gn)"
            class="flex w-full items-center after:w-full after:h-0.5 after:border-b after:border-indigo-600 dark:after:border-indigo-300 after:border-dashed last:after:border-none last:w-fit">
            <span class="flex flex-col items-center justify-center w-14 h-14 rounded-full lg:h-20 lg:w-20 border border-dashed border-indigo-800 dark:border-indigo-300 shrink-0 font-semibold"
            [ngClass]="{'bg-indigo-700 dark:bg-indigo-300 text-indigo-50 dark:text-gray-900' : gameNightIsSelected(gn)}">
                <span class="text-xs">{{gn.date | date:'MMM d'}}</span>
                <span class="text-sm">{{gn.user?.userName}}</span>
            </span>
        </li>
        <li *ngIf="gameNights.length > 0"
            class="flex w-full items-center after:w-full after:h-0.5 after:border-b after:border-indigo-600 dark:after:border-indigo-300 after:border-dashed last:after:border-none last:w-fit">
            <span class="flex flex-col items-center justify-center w-14 h-14 rounded-full lg:h-20 lg:w-20 border border-dashed border-indigo-800 dark:border-indigo-300 shrink-0 font-semibold"
            (click)="createGameNight()">Add</span>
        </li>
    </ol>
</div>