import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bowling-article',
  templateUrl: './bowling-article.component.html',
  styleUrls: ['./bowling-article.component.scss']
})
export class BowlingArticleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
