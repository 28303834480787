<div *ngIf="getSelectedGameNight()" class="px-6">
    <div class="flex flex-col font-medium text-xl text-center mb-10">
        <span>{{gameNightBelongsToLoggedInUser() ? "Select your" : "Viewing"}} games for</span>
        <span class="emphasis">{{getSelectedGameNight().date | date: 'EEEE, MMMM d'}}</span>
    </div>
    <div class="mb-2 overflow-y-auto flex flex-col space-y-5 border-b pb-6">
        <div *ngFor="let game of games"
            (click)="toggleGameSelected(game)" 
            class="flex flex-row justify-between">
                <div class="flex flex-row space-x-2">
                    <mat-icon *ngIf="!isGameSelected(game)">check_circle_outline</mat-icon>
                    <mat-icon *ngIf="isGameSelected(game)" class="text-pink-600">check_circle</mat-icon>
                    <span class="overflow-ellipsis">{{game.name}}</span>
                </div>
                <div class="flex flex-row space-x-2 w-1/5 justify-end">
                    <div class="flex flex-row w-1/2 justify-between">
                        <span>{{game.minPlayers}}</span>
                        <span>-</span>
                        <span>{{game.maxPlayers}}</span>
                    </div>
                    <mat-icon>person</mat-icon>
                </div>
        </div>
    </div>
    <div *ngIf="!addingGames" class="flex flex-row space-x-2 pt-4" (click)="addingGames = true">
        <mat-icon>add</mat-icon>
        <span>Add Game</span>
    </div>
    <form [formGroup]="form" class="flex flex-col space-y-4 relative pt-4" *ngIf="addingGames">
        <span class="absolute top-0 right-0" (click)="addingGames = false">
            <mat-icon>highlight_remove</mat-icon>
        </span>
        <div class="flex flex-row space-x-4">
            <input class="w-full" type="text" placeholder="Game" id="name" formControlName="name" />
          </div>
        <div class="flex flex-row space-x-4">
            <input class="w-1/3" type="number" placeholder="Min" id="minPlayers" formControlName="minPlayers" />
            <input class="w-1/3" type="number" placeholder="Max" id="maxPlayers" formControlName="maxPlayers" />
            <div class="w-1/3"><button type="submit" class="btn btn-primary w-full h-full" [disabled]="!form.valid" (click)="addGame()">Add</button></div>
        </div> 
    </form>
</div>