<div *ngIf="getSelectedGameNight()" class="px-6">
    <div class="flex flex-col font-medium text-xl text-center mb-12">
        <span *ngIf="!getSelectedGameNight().meal">{{userCanSetMeal() ? "Select" : "Suggest"}} the meal for</span>
        <span *ngIf="getSelectedGameNight().meal">We're having <span class="emphasis">{{getSelectedGameNight().meal.name | lowercase}}</span> on</span>
        <span class="emphasis">{{getSelectedGameNight().date | date: 'EEEE, MMMM d'}}</span>
    </div>
    <div class="mb-2 overflow-y-auto flex flex-col space-y-4 border-b pb-6">
        <div *ngFor="let meal of meals"
            (click)="setMeal(meal)">
            <span [ngClass]="{ 'font-bold' : getSelectedGameNight().meal?.id === meal.id }">{{meal.name}}</span>
        </div>
    </div>
    <div *ngIf="!addingMeal" class="flex flex-row space-x-2 pt-4" (click)="addingMeal = true">
        <mat-icon>add</mat-icon>
        <span>Add Meal</span>
    </div>
    <form [formGroup]="form" class="flex flex-col space-y-4 relative pt-4" *ngIf="addingMeal">
        <span class="absolute top-0 right-0" (click)="addingMeal = false">
            <mat-icon>highlight_remove</mat-icon>
        </span>
        <div class="flex flex-row space-x-4">
            <input class="w-2/3" type="text" placeholder="Meal" id="name" formControlName="name" />
            <div class="w-1/3"><button type="submit" class="btn btn-primary w-full h-full" [disabled]="!form.valid" (click)="addMeal()">Add</button></div>
          </div>
    </form>
</div>