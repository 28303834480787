import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GameNight, GameNightGame, GameNightMeal, GameNightUserStatus } from '../models/game-night';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';

@Injectable({
  providedIn: 'root'
})
export class GameNightService {
  private startDateTime: number;
  
  public selectedGameNight: GameNight;
  public skippingNight: boolean = false;
  public addingGameNight: boolean = false;
  
  private visibleGameNightsSubject: ReplaySubject<GameNight[]> = new ReplaySubject();
  private gamesSubject: ReplaySubject<GameNightGame[]> = new ReplaySubject();
  private mealsSubject: ReplaySubject<GameNightMeal[]> = new ReplaySubject();
  private usersSubject: ReplaySubject<User[]> = new ReplaySubject();
  private nextUserIdSubject: ReplaySubject<string> = new ReplaySubject();
  private nextGameNightDateSubject: ReplaySubject<Date> = new ReplaySubject();

  constructor(private http: HttpClient, private auth: AuthService) { 
    this.startDateTime = new Date().getTime();
    this.loadGameNights();
    this.loadGames();
    this.loadMeals();
    this.loadUsers();
  }

  public userCanSetMeal(): boolean {
    const userName = this.auth.getLoggedInUserName().toLowerCase();
    return userName === "mom" || userName === "nick";
  }

  public gameNightBelongsToLoggedInUser(): boolean {
    return this.auth.getLoggedInUserId() === this.selectedGameNight?.user?.id;
  }

  public visibleGameNights(): Observable<GameNight[]> {
    return this.visibleGameNightsSubject.asObservable();
  }

  public games(): Observable<GameNightGame[]> {
    return this.gamesSubject.asObservable();
  }

  public meals(): Observable<GameNightMeal[]> {
    return this.mealsSubject.asObservable();
  }

  public users(): Observable<User[]> {
    return this.usersSubject.asObservable();
  }

  public nextUserId(): Observable<string> {
    return this.nextUserIdSubject.asObservable();
  }

  public nextGameNightDate(): Observable<Date> {
    return this.nextGameNightDateSubject.asObservable();
  }

  public selectGameNight(gn: GameNight): void {
    this.selectedGameNight = gn;
  }

  public createGameNight(gn: GameNight) {
    this.http.post<GameNight>("GameNight/Create", gn).subscribe(() => {
      this.addingGameNight = false;  
      this.loadGameNights();
    });
  }

  public saveGames(gn: GameNight) {
    this.http.post<GameNight>("GameNight/SaveGames", gn).subscribe(() => this.loadGameNights());
  }

  public saveMeal(gn: GameNight) {
    this.http.post<GameNight>("GameNight/SaveMeal", gn).subscribe(() => this.loadGameNights());
  }

  public saveUserStatus(status: GameNightUserStatus) {
    this.http.post<GameNight>("GameNight/SaveUserStatus", status).subscribe(() => this.loadGameNights());
  }

  public addGame(g: GameNightGame) {
    this.http.post<GameNightGame>("GameNight/AddGame", g).subscribe(() => this.loadGames());
  }

  public addMeal(m: GameNightMeal) {
    this.http.post<GameNightMeal>("GameNight/AddMeal", m).subscribe(() => this.loadMeals());
  }

  private loadGameNights(gameNightsToLoad: number = 5): void {
    this.http
      .get<GameNight[]>(`GameNight/GetGameNights/${this.startDateTime}/${gameNightsToLoad}`)
      .subscribe(data => {
        this.visibleGameNightsSubject.next(data);
        if (!this.selectedGameNight && data.length > 0)
          this.selectGameNight(data[0]);
        else if (this.selectedGameNight)
          this.selectGameNight(data.find(g => g.id === this.selectedGameNight.id) || data[0]);
        else 
          this.addingGameNight = true;
      });

    this.http
      .get<GameNight>("GameNight/GetNextGameNightDefaults")
      .subscribe(data => {
        this.nextGameNightDateSubject.next(data.date);
        this.nextUserIdSubject.next(data.userId);
      });
  }

  private loadGames(): void {
    this.http
      .get<GameNightGame[]>(`GameNight/GetGames`)
      .subscribe(data => {
        this.gamesSubject.next(data);
      });
  }

  private loadMeals(): void {
    this.http
      .get<GameNightMeal[]>(`GameNight/GetMeals`)
      .subscribe(data => {
        this.mealsSubject.next(data);
      });
  }

  private loadUsers(): void {
    this.http
      .get<User[]>(`GameNight/GetUsers`)
      .subscribe(data => {
        this.usersSubject.next(data);
      });
  }
}
