import { Component, OnInit } from '@angular/core';
import { GameNightService } from '../../services/game-night.service';
import { User } from 'src/app/auth/user';
import { GameNight } from '../../models/game-night';

@Component({
  selector: 'app-create-game-night',
  templateUrl: './create-game-night.component.html',
  styleUrls: ['./create-game-night.component.scss']
})
export class CreateGameNightComponent implements OnInit {

  gameNightDate: Date;
  selectedUserId: string;

  users: User[];

  constructor(private gnService: GameNightService) { 

  }

  ngOnInit(): void {
    this.gnService.users().subscribe(data => {
      this.users = data;
    });

    this.gnService.nextUserId().subscribe(data => {
      this.selectedUserId = data;
    });

    this.gnService.nextGameNightDate().subscribe(data => {
      this.gameNightDate = data;
    });
  }
  
  public createGameNight() {
    let gn = new GameNight();
    gn.date = this.gameNightDate;
    gn.userId = this.selectedUserId;
    this.gnService.createGameNight(gn);
  }
}
